@import "styles/utils.module";
@import "styles/color.module";


.container {
  padding-top: 60px - $topPadding;
}

@include tablet {
  .container {
    padding: 0 16px;
  }
}

@include mobile {
  .container {
    padding: 0;
  }
}

.pageTitle {
  font-weight: bold;
  font-size: 26px;
  line-height: 100%;
  color: $typo4;
  margin: 0 0 40px 0;
}

@include tablet {
  .pageTitle {
    margin-bottom: 24px;
    font-size: 22px;
  }
}

.cardWrapper {
  height: 100%;
}

.contentSection {
  padding: 0;
  margin-top: -12px;
  margin-bottom: 12px;
}

.col {
  flex: 0 0 50%;
  margin-top: 32px;
  padding: 0 0;
  &:nth-child(2n) {
    padding-left: 1.5px;
  }

  &:nth-child(2n+1) {
    padding-right: 1.5px;
  }
}

.topListContainer {
  //margin-left: -$layoutLeftPadding;
  //margin-right: -$layoutRightPadding;
}

@include tablet {
  .col {
    padding: 0;
  }
  .row {
  }
  .topListContainer {
    margin: 0;
  }
}

@include mobile {
  .col {}

  .topListContainer {
    padding: 0;
    //margin-left: -5px;
    //margin-right: -5px;
  }
}
