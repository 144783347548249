@import "components/layout/layout.module";
@import "styles/utils.module";
@import "styles/color.module";

.resultContainer {
    font-size: 14px;
    font-weight: 500;
    color: $typo2;
    @extend .flexRow;
    @extend .justifyContentSpaceBetween;
    @extend .no-select;
    @extend .alignItemsCenter;
}

@include mobile {
    .resultContainer {
        font-size: 13px;
    }
}

.sectionContainer {
    margin-top: 20px;
}

.topListContainer {

}

@include tablet {
    .topListContainer {
        padding-bottom: 60px;
    }
}


@include mobile {
    .topListContainer {
        padding-bottom: 40px;
    }
}


.seeMore {
    color: $brand;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    text-decoration: underline;
    margin-right: 4px;
}

.seeMoreIcon {}

@include tablet {
    .seeMore {
        font-size: 14px;
    }
}

@include mobile {
    .seeMore {
        margin-right: 2px;
    }
    .seeMoreIcon {
        width: 12px;
        height: 12px;
    }
}

.subCategoryTitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    color: $typo4;
}

.mainSectionTitle {
    padding-bottom: 40px;
}

@include tablet {
    .mainSectionTitle {
        padding-bottom: 20px;
    }
}

@include mobile {
    .mainSectionTitle {
        margin-top: 24px;
    }

    .subCategoryTitle {
        font-size: 18px;
    }
}

.topMargin {
    margin-top: 60 - $topPadding;
}

.titleTopMargin {
    height: 60 - $topPadding;
}

.topTabBarMargin {
    height: 40px;
}

.paginatorMargin {
    height: 20px;
}

.pageBottomMargin {
    height: 80px;
}

.productGridMargin {}

@include tablet {
    .topMargin {
        margin-top: 0;
    }

    .titleTopMargin {
        height: 40 - $topPadding;
    }

    .topTabBarMargin {
        height: 24px;
    }

    .productGridMargin {
        height: 4px;
    }

    .pageBottomMargin {
        height: 140px;
    }

    .paginatorMargin {
        height: 0px;
    }
}

@include mobile {
    .topMargin {
        margin-top: 24px;
    }

    .titleTopMargin {
        //margin-top: 12px;
        height: 24px;
    }

    .topTabBarMargin {
        height: 4px;
    }

    .productGridMargin {
        height: 0;
    }

    .pageBottomMargin {
        height: 80px;
    }
}

.shortcutSection {
    @extend .flexRow;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
    padding: 20px 5px 24px 5px;
}

.shortcutWrapper {
    @extend .flexRow;
    flex: 1;
    flex-wrap: wrap;
    margin-top: -24px;
}

.shortcut {
    @extend .flexCol;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
    flex: 0 0 25%;
    margin-top: 24px;
}

.icon {
    width: 28px;
    height: 28px;
    @extend .alignItemsCenter;
    @extend .justifyContentCenter;
}

.shortcutText {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 500;
    line-height: 120%;
    color: $typo3;
    //white-space: nowrap;
}

.specialOfferSection {
    padding: 20px 0;
}

.sectionTitleWrapper {
    margin: 0 $layoutRightPaddingNew 16px $layoutLeftPaddingNew;
    @extend .flexRow;
    justify-content: space-between;
    align-items: center;
}

.sectionTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;

    color: #191919;
}

.productsWrapper {
    @extend .flexRow;
    flex-wrap: nowrap;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    & > *+* {
        margin-left: 4px;
    }
}

.cardWrapper {
    text-align: left;
    flex: 0 0 135px;
}

.topTabBarWrapper {
    scroll-margin-top: $topBarHeightMobile + $mobilStoreNavBarHeight;
}

.horizontalScrollPaddingLeft {
    flex: 0 0 $layoutLeftPaddingNew - 4px;
}

.horizontalScrollPaddingRight {
    flex: 0 0 $layoutRightPaddingNew - 4px;
}
