@import "styles/utils.module";
@import "styles/color.module";

.row {
  min-height: 1px;
  margin: 20px 0 0 0;
}

.col {
  padding: 0 0;
  margin-bottom: 32px;
  &:nth-child(2n) {
    padding-left: 1.5px;
  }

  &:nth-child(2n+1) {
    padding-right: 1.5px;
  }
}